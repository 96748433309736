<template>
  <v-footer
    id="dashboard-core-footer"
    absolute
  >
    <v-container
      id="footer-container"
      fluid
    >
      <v-row
        align="center"
        no-gutters
      >
        <a
          href="http://www.dbsystems.com.au"
          target="_blank"
          rel="noopener noreferrer"
        >
          <font class="company-name">
            dibasys
          </font>
        </a>
        <v-spacer class="hidden-sm-and-down" />
        <v-col
          cols="12"
          md="auto"
        >
          <div class="my-footer-style">
            Dibasys Pvt Ltd
          </div>
        </v-col>
      </v-row>
    </v-container>
  </v-footer>
</template>

<script>

  export default {
    name: 'DashboardCoreFooter',
  };
</script>

<style lang="sass">
  #dashboard-core-footer
    a
      font-size: .825rem
      font-weight: 500
      text-decoration: none
    height: 0.1px
  #footer-container
    padding: 10px
    background-color: #CFD8DC
</style>
<style scoped>
.my-footer-style {
  color: #37474F;
  font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
  font-weight: 600;
  margin-right: 20px;
  font-size: 16px;
}
.company-name {
  font-family: 'Bruno Ace';
  font-size: 16px;
  color: #FF3700;
  margin-left: 10px;
  letter-spacing: 3px;
  font-weight: 600;
}
</style>
